import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import StoryblokComponents from '../storyblok/StoryblokComponents';

//

const LoadStoryblokBridge = (cb) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${process.env.GATSBY_STORYBLOK_TOKEN}`;
  script.onload = cb;

  document.getElementsByTagName('head')[0].appendChild(script);
};

//

const StoryblokEntry = () => {
  const [story, setStory] = useState(null);

  const loadStory = useCallback(() => {
    window.storyblok.get(
      {
        slug: window.storyblok.getParam('path'),
        version: 'draft',
        resolve_relations: true,
      },
      (data) => {
        setStory(data.story);
      }
    );
  }, []);

  const initStoryblokEvents = useCallback(() => {
    loadStory();

    const sb = window.storyblok;

    sb.on(['change', 'published'], () => {
      loadStory();
    });

    sb.on(['input'], (payload) => {
      payload.story.content = sb.addComments(
        payload.story.content,
        payload.story.id
      );
      setStory(payload.story);
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }, [loadStory]);

  useEffect(() => {
    LoadStoryblokBridge(() => {
      initStoryblokEvents();
    });
  }, [initStoryblokEvents]);

  if (!story) {
    return null;
  }

  const { content } = story;

  console.info(content);

  return (
    <>
      <Helmet>
        <style type="text/css">{`
          a {
            pointer-events: none;
          }

          [data-sal|="fade"] {
            opacity: 1 !important;
          }
        `}</style>
      </Helmet>

      {content.block &&
        React.createElement(StoryblokComponents(content.component), {
          key: content._uid,
          content: content.block,
        })}
    </>
  );
};

export default StoryblokEntry;
